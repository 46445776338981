import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
 
const Modal = ({children, content, css}) => (
    <Popup className={css} trigger={children} position="top center" modal>
      {content}
    </Popup>
  );

export default Modal;
