import { graphql, Link, useStaticQuery, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"
import { getUser, isLoggedIn, logout } from "./services/auth"
import TranslateCN from "../intl/cn.json"
import TranslateEN from "../intl/en.json"

const Header = ({locale, langsMenu}) => {

  const useStyles = {
    appBarTransparent: "transition duration-500 ease-in",
    appBarSolid: "bg-black md:bg-opacity-75 transition duration-500 ease-in",
    appBarHidden: "hidden",
  }
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [navBackground, setNavBackground] = useState('appBarTransparent')
  const [scrolling, setScrolling]  = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  let langSwitchLink = ""
  langsMenu.map((lang)=>{
    if (lang.selected) {
      locale = lang.langKey
    } else {
      langSwitchLink = lang.link
    }
  })

  let buttonTitle = {}
  if (locale === "zh-CN") {
        buttonTitle =  TranslateCN
    } else {
        buttonTitle = TranslateEN
  }

  const navRef = React.useRef()
  navRef.current = navBackground

  const handleLogout = () => {
    navigate(`/${locale}/crm/login`)
  }



  useEffect(()=> {



    const handleScroll = () => {
      setScrollTop(document.body.getBoundingClientRect().top);
      setScrolling(document.body.getBoundingClientRect().top > scrollTop);

      const show = scrolling

      if (show) {
        document.body.getBoundingClientRect().top === 0 ? setNavBackground('appBarTransparent') : setNavBackground('appBarTransparent')
      } else {
        setNavBackground('appBarTransparent')

      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll',handleScroll)
    }
  },[scrollTop])

  const { site, mobileMenuBg } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
      mobileMenuBg: contentfulAsset(title: {eq: "MobileMenuBgImg"}) {
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    }
  `)
  
  return (
      <div className={`w-screen py-6 md:pt-0 md:pb-0 fixed md:top-0 z-50 head ${useStyles[navRef.current]}`}>
        <div className="flex flex-row-reverse md:flex-row justify-between items-center md:border-b md:border-opacity-50 md:border-white max-w-4/5 xl:max-w-3/4 md:pb-3/10rem my-0 mx-auto">
          <Link to="/">
            <img alt="Logo" className="w-20 md:w-22 pt-2" src="/logo.svg" />
          </Link>

          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Menu"
          >
            <FaBars className="h-6 w-auto text-white fill-current -mt-1" />
          </button>

          <div className="hidden md:block">
            <div className="flex justify-end mb-1">
            {/*
              isLoggedIn() ?
              <a href={`/${locale}/crm/profile`} className="text-xs sm:text-xs font-medium px-4 pt-6 border-b-4 pb-1 border-transparent text-white hover:text-white hover:bg-white hover:bg-opacity-25 transition duration-150 ease-in-out hover:cursor-pointer">{getUser().mobile}</a>
              :
              <a href={`/${locale}/crm/register`} className="text-xs sm:text-xs font-medium px-4 pt-6 border-b-4 pb-1 border-transparent text-white hover:text-white hover:bg-white hover:bg-opacity-25 transition duration-150 ease-in-out hover:cursor-pointer">{buttonTitle.header.register}</a>
            */}
              <a href={langSwitchLink} target="_self" className="text-xs sm:text-xs font-medium px-4 pt-6 border-b-4 pb-1 border-transparent text-white hover:text-white hover:bg-white hover:bg-opacity-25 transition duration-150 ease-in-out hover:cursor-pointer">中文/EN</a>
              {/*
                isLoggedIn() ?
                <a className="pt-6 px-4 text-xs sm:text-xs font-medium hover:bg-white hover:bg-opacity-25 transition duration-300 ease-in-out hover:cursor-pointer" 
                href="/"
                onClick={event => {
                  event.preventDefault()
                  logout(handleLogout)
                }}
                >
                {buttonTitle.header.logout}
                </a> : <a className="pt-6 px-4 hover:bg-white hover:bg-opacity-25 transition duration-300 ease-in-out hover:cursor-pointer" href={`/${locale}/crm/login`}><img className="w-3" src="/profile.svg" alt="profile"/></a>
              */}
            </div>
            <div className="md:text-right">
              {site.data.menu.map((link, key) => {
                if(link.to.substring(0,1) === "/") {
                return (<Link
                  key={`menu_desktop_link${key}`}
                  className="ml-6 text-xs border-b-4 pb-2 px-px border-transparent centralesans-medium opacity-50 hover:opacity-100 text-white hover:border-white uppercase transition duration-150 ease-in-out"
                  activeClassName="text-white border-white hover:border-white opacity-100"
                  to={`/${locale}${link.to}`}
                >
                  {link.name}
                </Link>)}
                else {
                  return (<Link
                  key={`menu_desktop_link${key}`}
                  className="font-bold ml-6 text-xs border-b-4 pb-2 px-px border-transparent centralesans-medium opacity-50 hover:opacity-100 text-white hover:border-white uppercase transition duration-150 ease-in-out"
                  activeClassName="text-white border-white hover:border-white opacity-100"
                  to={link.to}
                >
                  {link.name}
                </Link>)}
              })}
            </div>
          </div>
        </div>
        <MenuMobile
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          langsMenu={langsMenu}
          links={site.data.menu}
          bgImage={mobileMenuBg}
        />
      </div>
  )
}

export default Header
