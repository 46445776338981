import { graphql, useStaticQuery } from "gatsby"
import React, {useState} from "react"
import Button from "./Button"
import Img from 'gatsby-image'
import Modal from "./Modal"
import FooterCN from "../intl/cn.json"
import FooterEN from "../intl/en.json"


const Footer = ({locale}) => {

  const [open, setOpen] = useState(false)

  const {
    site: {
      meta: { links },
    },
    wechat,
    weibo,
    contentCn,
    contentEn,
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            facebook
            instagram
            pinterest
            twitter
          }
        }
      }
      wechat: contentfulAsset(title: {eq: "Wechat QR code"}) {
          fluid(quality: 80, maxWidth: 300) {
            ...GatsbyContentfulFluid
          }
      }
      weibo: contentfulAsset(title: {eq: "Weibo QR Code"}) {
          fluid(quality: 80, maxWidth: 300) {
            ...GatsbyContentfulFluid
          }
      }
      contentCn: allContentfulFooter(filter: {node_locale: {eq: "zh-CN"}}) {
        edges {
          node {
            node_locale
            year
            pagesLink
            pagesContent
            introductionContent {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      contentEn: allContentfulFooter(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            node_locale
            year
            pagesContent
            pagesLink
            introductionContent {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)
  
  let footerTitle, footerContent = {}
  
  if (locale === "zh-CN") {
    footerContent = contentCn.edges[0].node
    footerTitle =  FooterCN
  } else {
    footerContent = contentEn.edges[0].node
    footerTitle = FooterEN
  }
  return (

    <div className="pt-8 border-t border-white border-opacity-50 relative">
      <div className="w-3/4 mx-auto flex flex-col items-start md:items-center">
      <img alt="Logo" className="py-1 w-24 md:w-32 md:py-3" src="/logo.svg" />
      <div className="w-full mx-auto flex flex-row justify-start border-b border-white border-opacity-50 text-xxxs md:text-xxs py-3 md:justify-center">
        <div className="px-1 lg:px-2">{`@ ${footerContent.year} ZIPPO `}</div>
        <div><a href="/privacy" className="px-1 lg:px-2 opacity-75 hover:opacity-100">{footerTitle.footer.privacy}</a></div>
        {/*<div className="px-1 lg:px-2 opacity-75 hover:opacity-100">|</div>*/}
        {/*<div><a href="https://beian.miit.gov.cn" className="px-1 lg:px-2 opacity-75 hover:opacity-100">京ICP备15066446号-2</a></div>*/}
      </div>
      <div className="grid grid-cols-0 md:grid-cols-7 md:gap-12 md:pt-12">
        <div className="col-span-3 md:flex md:justify-left md:flex-col">
          <div className="centralesans-xbold pt-2 pb-1 md:pt-0 md:pb-5">{footerTitle.footer.intro}</div>
          <div className="text-xxs md:text-xs text-justify opacity-75 leading-2 md:leading-6 pt-1 uppercase lg:max-w-3/4"> 
          <section style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{ __html: footerContent.introductionContent.childMarkdownRemark.html }} />
          </div>
        </div>
        <div className="hidden md:col-span-2 mt-4 md:mt-0 md:px-5 md:flex md:justify-left md:flex-col">
          <div className="centralesans-xbold pb-5">{footerTitle.footer.pages}</div>
          {
            footerContent.pagesContent.map((content, index)=> {
              return (
                <a key={`footer-${index}`} href={`/${footerContent.node_locale}/${footerContent.pagesLink[index]}`} className="text-xs opacity-75 hover:opacity-100 py-1">{`${content}`}</a>
              )
            })
          }
        </div>
        <div className="col-span-2 md:px-5 flex justify-left flex-col">
          <div className="hidden md:block">
            <div className="centralesans-xbold pb-5">{footerTitle.footer.newsletter}</div>
            <div className="text-xs opacity-75">{footerTitle.footer.newsletterSub}</div>
            <form className="pt-3 flex flex-col">
              <input
                aria-label="Email address"
                className="px-3 py-2 text-xs border border-1 bg-transparent focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                // onChange={event => setEmail(event.target.value)}
                placeholder={footerTitle.footer.newsletterPlaceholder}
                required
                type="email"
              />
              <div className="py-3">
                <Button>{footerTitle.footer.signUp}</Button>
              </div>
            </form>
            <div className="text-xs opacity-75 leading-6 uppercase">{footerTitle.footer.disclaimer}</div>
          </div>
          <div className="py-3 md:py-0">
            <a href={`/${footerContent.node_locale}/career`} target="_self" className="block text-xs py-3 md:py-0 md:pt-3">{footerTitle.footer.career}</a>
            <a href="http://101.200.33.112/cn/support" className="block text-xs md:my-3">{footerTitle.footer.support}</a>
            <a href="http://101.200.33.112/cn/group/" className="block text-xs py-3 md:py-0 md:my-3">{footerTitle.footer.purchase}</a>
            <div className="flex flex-row py-3">
              <div>
                <div className="pr-3" onClick={() => setOpen(true)} >
                <Modal css="icon" setOpen={setOpen} open={open} content={<Img fluid={wechat.fluid}/>}>
                  <svg xmlns="http://www.w3.org/2000/svg" id="weixin" className="h-6 w-6 md:h-4 md:w-4 cursor-pointer" fill="#fff" viewBox="0 0 576 512">
                    <path d="M385.2 167.6c6.4 0 12.6.3 18.8 1.1C387.4 90.3 303.3 32 207.7 32 100.5 32 13 104.8 13 197.4c0 53.4 29.3 97.5 77.9 131.6l-19.3 58.6 68-34.1c24.4 4.8 43.8 9.7 68.2 9.7 6.2 0 12.1-.3 18.3-.8-4-12.9-6.2-26.6-6.2-40.8-.1-84.9 72.9-154 165.3-154zm-104.5-52.9c14.5 0 24.2 9.7 24.2 24.4 0 14.5-9.7 24.2-24.2 24.2-14.8 0-29.3-9.7-29.3-24.2.1-14.7 14.6-24.4 29.3-24.4zm-136.4 48.6c-14.5 0-29.3-9.7-29.3-24.2 0-14.8 14.8-24.4 29.3-24.4 14.8 0 24.4 9.7 24.4 24.4 0 14.6-9.6 24.2-24.4 24.2zM563 319.4c0-77.9-77.9-141.3-165.4-141.3-92.7 0-165.4 63.4-165.4 141.3S305 460.7 397.6 460.7c19.3 0 38.9-5.1 58.6-9.9l53.4 29.3-14.8-48.6C534 402.1 563 363.2 563 319.4zm-219.1-24.5c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.8 0 24.4 9.7 24.4 19.3 0 10-9.7 19.6-24.4 19.6zm107.1 0c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.5 0 24.4 9.7 24.4 19.3.1 10-9.9 19.6-24.4 19.6z"></path>
                  </svg>
                  </Modal>
                </div>
              </div>
              <div>
                <div onClick={() => setOpen(true)} >
                <Modal css="icon" setOpen={setOpen} open={open} content={<Img fluid={weibo.fluid}/>}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="weibo" className="h-6 w-6 md:h-4 md:w-4  cursor-pointer" fill="#fff">
                    <path d="M407 177.6c7.6-24-13.4-46.8-37.4-41.7-22 4.8-28.8-28.1-7.1-32.8 50.1-10.9 92.3 37.1 76.5 84.8-6.8 21.2-38.8 10.8-32-10.3zM214.8 446.7C108.5 446.7 0 395.3 0 310.4c0-44.3 28-95.4 76.3-143.7C176 67 279.5 65.8 249.9 161c-4 13.1 12.3 5.7 12.3 6 79.5-33.6 140.5-16.8 114 51.4-3.7 9.4 1.1 10.9 8.3 13.1 135.7 42.3 34.8 215.2-169.7 215.2zm143.7-146.3c-5.4-55.7-78.5-94-163.4-85.7-84.8 8.6-148.8 60.3-143.4 116s78.5 94 163.4 85.7c84.8-8.6 148.8-60.3 143.4-116zM347.9 35.1c-25.9 5.6-16.8 43.7 8.3 38.3 72.3-15.2 134.8 52.8 111.7 124-7.4 24.2 29.1 37 37.4 12 31.9-99.8-55.1-195.9-157.4-174.3zm-78.5 311c-17.1 38.8-66.8 60-109.1 46.3-40.8-13.1-58-53.4-40.3-89.7 17.7-35.4 63.1-55.4 103.4-45.1 42 10.8 63.1 50.2 46 88.5zm-86.3-30c-12.9-5.4-30 .3-38 12.9-8.3 12.9-4.3 28 8.6 34 13.1 6 30.8.3 39.1-12.9 8-13.1 3.7-28.3-9.7-34zm32.6-13.4c-5.1-1.7-11.4.6-14.3 5.4-2.9 5.1-1.4 10.6 3.7 12.9 5.1 2 11.7-.3 14.6-5.4 2.8-5.2 1.1-10.9-4-12.9z"/>
                  </svg>
                </Modal>
                </div>
              </div>
            </div>
          </div>
            
        </div>
      </div>
        <div><a href="https://beian.miit.gov.cn" className="block pt-20 text-xxs md:text-xs px-1 lg:px-2 opacity-25 hover:opacity-100">京ICP备15066446号-2</a></div>
      </div>
    </div>

  )
}

export default Footer
