import { Link, navigate } from "gatsby"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import React from "react"
import { getUser, isLoggedIn, logout } from "./services/auth"
import { FaBars } from "react-icons/fa"
import Overlay from "./Overlay"
import TranslateCN from "../intl/cn.json"
import TranslateEN from "../intl/en.json"


const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: key => ({
    opacity: 1,
    transition: {
      delay: 0.25 + key * 0.1,
      type: "tween",
    },
    x: 0,
  }),
}

const MenuMobile = ({ links, isOpen, setIsOpen, bgImage, langsMenu}) => {

  let langSwitchLink, locale = ""
  langsMenu.map((lang)=>{
    if (lang.selected) {
      locale = lang.langKey
    } else {
      langSwitchLink = lang.link
    }
  })

  let buttonTitle = {}
  if (locale === "zh-CN") {
        buttonTitle =  TranslateCN
    } else {
        buttonTitle = TranslateEN
  }


  const handleLogout = () => {
    navigate(`/${locale}/crm/login`)
  }

  return (
    <Overlay bgImage={bgImage} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex flex-col justify-center">
        <ul className="text-center">
          {links.map((link, key) => {
            if(link.to.substring(0,1) === "/") {
              return (
            <motion.li
              className="my-3"
              animate={isOpen ? "open" : "closed"}
              custom={key}
              key={`menu_mobile_link${key}`}
              variants={menuItem}
            >
              <Link
                className="text-outline centralesans-bold text-4xl"
                activeClassName="text-white centralesans-bold line-before-after"
                to={`/${locale}${link.to}`}
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </Link>
            </motion.li>)}
            else {
              return (
                <motion.li
                  className="my-3"
                  animate={isOpen ? "open" : "closed"}
                  custom={key}
                  key={`menu_mobile_link${key}`}
                  variants={menuItem}
                >
                  <Link
                    className="text-outline centralesans-bold text-4xl"
                    activeClassName="text-white centralesans-bold line-before-after"
                    to={link.to}
                    onClick={() => setIsOpen(false)}
                  >
                    {link.name}
                  </Link>
                </motion.li>)
            }
          })}
          <a href={langSwitchLink} target="_self">中文/EN</a>
          <div className="mt-8 flex justify-center ">
            {/*
              isLoggedIn() ?
              <a href={`/${locale}/crm/profile`} className="centralesans-bold text-l">{getUser().mobile}</a>
              :
              <a href={`/${locale}/crm/register`} className="centralesans-bold text-l mx-4">{buttonTitle.header.register}</a>
            }
            {
              isLoggedIn() ?
              <a className="centralesans-bold text-l mx-4" 
              href="/"
              onClick={event => {
                event.preventDefault()
                logout(handleLogout)
              }}
              >
              {buttonTitle.header.logout}
              </a> : <a className="centralesans-bold mx-4" href={`/${locale}/crm/login`}><img className="w-4" src="/profile.svg" alt="profile"/></a>
            */}
          </div>
        </ul>
      </div>
    </Overlay>
  )
}

MenuMobile.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default MenuMobile
