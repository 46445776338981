import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const SiteMetadata = ({ title, description, image, languages }) => {
  const {
    site: {
      siteMetadata: { siteTitle, siteDescription, siteKeywords },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteTitle: title,
          siteDescription: description,
          siteKeywords: keywords,
        }
      }
    }
  `)

  return (
    <Helmet
      defer={false}
      defaultTitle={siteTitle}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={languages} />
      <meta charset="UTF-8" />
      <meta http-equiv="imagetoolbar" content="no" />
      <meta http-equiv="x-ua-compatible" content="IE=Edge"/>
      <meta name="viewport" content="width=device-width,initial-scale=1, maximum-scale=1" />
      <meta name="description" content={description} />
      <meta name="keywords" content={siteKeywords} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content={languages} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={`${description} | ${siteDescription}` } />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={image} />
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />

      <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.5.1/gsap.min.js"></script>
      <script src="https://use.fontawesome.com/6479cbb3a3.js"></script>
    </Helmet>
  )
}

SiteMetadata.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
}

SiteMetadata.defaultProps = {
  image: "/social.png",
}

export default SiteMetadata
