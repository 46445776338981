export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.sessionStorage.getItem("gatsbyUser")
    ? JSON.parse(window.sessionStorage.getItem("gatsbyUser"))
    : {}

export const setUser = user =>
  window.sessionStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = (mobile) => {
  if (mobile){
    return setUser({
      mobile: mobile,
    })
  }

  return false
}

export const verifySms = (inputSms, systemSms) => {
  if (inputSms === systemSms) {
    return true
  }
  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.mobile
}

export const logout = callback => {
  setUser({})
  callback()
}