import PropTypes from "prop-types"
import React from "react"

const Button = ({ children, href, color, secondary, fc, product, ...params }) => {
  
  let className =
    `inline-block px-4 xl:px-8 py-2 text-xxs border border-1 ${color} bg-transparent hover:bg-white hover:bg-opacity-25 focus:outline-none transition duration-150 ease-in-out centralesans-bold`

  if(secondary){
    className =
    `inline-block px-4 xl:px-8 py-2 text-xxs border border-1 ${color} bg-transparent hover:bg-white hover:text-black focus:outline-none transition duration-150 ease-in-out centralesans-bold`
  } 

  if(fc){
    className =
    `inline-block px-4 xl:px-5 py-2 text-xxs border border-opacity-50 border-1 border-gray-300 ${color} bg-transparent hover:bg-opacity-25 hover:bg-white hover:border-white focus:outline-none transition duration-150 ease-in-out centralesans-bold`
  }

  if(product) {
    className =
    `inline-block px-4 py-2 text-xxs border border-1 ${color} bg-transparent hover:bg-white hover:bg-opacity-25 focus:outline-none transition duration-150 ease-in-out centralesans-bold`
  }

  if (href) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        className={className}
      >
        <span>{children}</span><span>&nbsp;&nbsp;&nbsp; ⮕</span>
      </a>
    )
  } else {
    return (
      <button className={className} {...params}>
        {children}
      </button>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}

Button.defaultProps = {
  href: null,
}

export default Button
